import React from "react";
import { DefaultLayout } from "../../organisms/DefaultLayout";
import * as styles from "../../styles/careers/employment-info.module.scss";

export default () => (
  <DefaultLayout>
    <div
      className={styles.cover}
      style={{ backgroundImage: "url('https://placehold.jp/1024x400.jpg')" }}
    ></div>
    <div className={styles.wrapper}>
      <div className={styles.h1}>
        <h1>Employment info</h1>
        <p>採用情報</p>
      </div>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <div className={styles.h2}>
        <h2>Application</h2>
        <p>募集要項</p>
      </div>
      <br />
      <br />
      <br />
      <br />
      <table className={styles.info}>
        <tr>
          <th>雇用形態</th>
          <td>
            正社員
            <p className={styles.cf}>※契約期間の定めなし</p>
            <p className={styles.cf}>※試用期間あり（3ヵ月）</p>
          </td>
        </tr>
        <tr>
          <th>募集職種</th>
          <td>
            <b>総合職（総合コース・エンジニアコース）</b>
            <p>変化に富んだ事業環境で、領域を定めず幅広い分野で事業を推進</p>
            <p className={styles.cf}>
              ※文理問わず、みなさんの経験、情熱を発揮できる場所で活躍していただきます
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              ダミー営業、企画、管理、技術など各部門における企画・立案、社内外調整、業務改善などの提案型サポート
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              ダミー営業、企画、管理、技術など各部門における企画・立案、社内外調整、業務改善などの提案型サポート
            </p>
          </td>
        </tr>
        <tr>
          <th>応募資格</th>
          <td>
            <b>総合職（総合コース・エンジニアコース）</b>
            <p>
              2022年3月末までに国内外の大学院、大学、高等専門学校を卒業・修了（見込み）の方
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              2022年3月末までに国内外の大学院、大学、短期大学、専門学校、高等専門学校を卒業・修了(見込み)の方
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              2022年3月末までに国内外の大学院、大学、短期大学、専門学校、高等専門学校を卒業・修了(見込み)の方
            </p>
            <p className={styles.cf}>
              ※入社時30歳未満の方
              ※2022年4月から2022年8月までに、海外の大学・学校を卒業・修了見込みの方は、卒業時期に合わせて2022年7月1日、10月1日の入社で調整しますのでお問い合わせください。
            </p>
          </td>
        </tr>
        <tr>
          <th>募集学科</th>
          <td>文理とも、全学部・全学科</td>
        </tr>
        <tr>
          <th>勤務予定地</th>
          <td>
            <b>2総合職（総合コース・エンジニアコース）</b>
            <p>
              <b>大阪本社・東京本社・名古屋市支社・福岡支社・札幌支社</b>
            </p>
            <p className={styles.cf}>※全国・海外転勤あり</p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              <b>大阪本社・東京本社・名古屋市支社・福岡支社・札幌支社</b>
            </p>
            <p className={styles.cf}>※全国・海外転勤なし</p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              <b>大阪本社・東京本社・名古屋市支社・福岡支社・札幌支社</b>
            </p>
            <p className={styles.cf}>※全国・海外転勤なし</p>
            <br />
          </td>
        </tr>
        <tr>
          <th>給与</th>
          <td>
            <b>総合職（総合コース・エンジニアコース）</b>
            <p> 高専卒（本科）：月給233,000円～</p>
            <p> 大　卒・高専卒（専攻科）：月給243,000円～</p>
            <p>修士了：月給264,200円〜</p>
            <p> 博士了：月給274,800円～</p>
            <p className={styles.cf}>
              ※卓越した能力、高度な技術や実績をお持ちの方で、それらを入社後の実業務において発揮できると認められる場合は、上記の給与に関わらず個別設定することがあります
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>月給205,000円</p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>月給205,000円</p>
            <br />
            <p>
              <b>全職種2021年度見込み</b>
            </p>
            <p className={styles.cf}>※自己成長支援金（1万円）を含む</p>
          </td>
        </tr>

        <tr>
          <th>昇給</th>
          <td>年1回（原則）</td>
        </tr>

        <tr>
          <th>賞与</th>
          <td>年2回 ※年間標準賞与5カ月＋特別加算賞与（業績により支給）</td>
        </tr>

        <tr>
          <th>昇給</th>
          <td>年1回（原則）</td>
        </tr>
        <tr>
          <th>勤務時間</th>
          <td>
            <b>総合職（総合コース・エンジニアコース）</b>
            <p>
              原則午前9時～午後5時45分（実働7時間45分＋休憩時間
              原則12時～13時までの1時間）
            </p>
            <p className={styles.cf}>
              ※スーパーフレックスタイム制（コアタイム無し）
              <br />
              ※一部、例外部署もあり（所定労働勤務・シフト勤務・交替制勤務など）
              <br />
              ※時間外労働あり
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              原則午前9時～午後5時45分（実働7時間45分＋休憩時間
              原則12時～13時までの1時間）
            </p>
            <p className={styles.cf}>
              ※スーパーフレックスタイム制（コアタイム無し）
              <br />
              ※一部、例外部署もあり（所定労働勤務・シフト勤務・交替制勤務など）
              <br />
              ※時間外労働あり
            </p>
            <br />
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              原則午前9時～午後5時45分（実働7時間45分＋休憩時間
              原則12時～13時までの1時間）
            </p>
            <p className={styles.cf}>
              ※スーパーフレックスタイム制（コアタイム無し）
              <br />
              ※一部、例外部署もあり（所定労働勤務・シフト勤務・交替制勤務など）
              <br />
              ※時間外労働あり
            </p>
          </td>
        </tr>
        <tr>
          <th>休日休暇</th>
          <td>
            週休2日制（年間休日日数
            126日/2019年度実績）、年次有給休暇、慶弔休暇、リフレッシュ休暇、
            産前産後休暇、育児休業、配偶者出産休暇、キッズ休暇、介護休暇、介護休業、看護休暇、ボランティア活動休暇、
            ドナー休暇、ハンディキャップ休暇（障がいのための通院休暇） など
            <p className={styles.cf}>
              ※休日休暇について、詳しくはこちらをご覧ください。
            </p>
          </td>
        </tr>

        <tr>
          <th>諸手当</th>
          <td>
            <b>総合職（総合コース・エンジニアコース）</b>
            <p>
              割増手当（時間外勤務手当、深夜勤務手当、休日勤務手当）、通勤手当、新卒住宅補助
              など
            </p>
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              割増手当（時間外勤務手当、深夜勤務手当、休日勤務手当）、通勤手当、新卒住宅補助
              など
            </p>
            <p>
              <b>アソシエイト職</b>
            </p>
            <p>
              割増手当（時間外勤務手当、深夜勤務手当、休日勤務手当）、通勤手当、新卒住宅補助
              など
            </p>
          </td>
        </tr>

        <tr>
          <th>福利厚生</th>
          <td>
            財形貯蓄、持株会、慶弔見舞金、確定拠出年金制度、育児支援制度、福利厚生サービス会社（ベネフィット・ワン）提携
            など
            <p className={styles.cf}>
              ※福利厚生について、詳しくはこちらをご覧ください。
            </p>
          </td>
        </tr>
        <tr>
          <th>研修制度</th>
          <td>
            能力開発体系に基づく研修（新入社員研修、3年目社員ステップアップ研修、新任管理職層研修、各種スキル研修、英語・統計・テクノロジー研修）、OJT
            など
            <p className={styles.cf}>
              ※キャリア（研修制度）について、詳しくはこちらをご覧ください。
            </p>
          </td>
        </tr>
        <tr>
          <th>保険</th>
          <td>健康保険、厚生年金、雇用保険、労災保険完備</td>
        </tr>
        <tr>
          <th>その他</th>
          <td>
            各キャリア開発支援制度（新規ポジションの社内公募、資格取得支援
            など）、パソコン・携帯電話貸与（弊社規定による）、モバイル特別販売、永年勤続表彰など
          </td>
        </tr>
      </table>
    </div>
    <br />
    <br />
    <br />
    <br />
    <br />

    <div className={styles.wrapperICP}>
      <div className={styles.h2}>
        <h2>Ideal Candidate Profile</h2>
        <p>求める人物像</p>
      </div>

      <div className={styles.dammy}>
        <p>
          <b>ダミー変化を楽しみ、</b>
        </p>
        <p>
          <b>何事もチャンスと捉え挑戦する人</b>
        </p>
      </div>
      <div className={styles.dammycontents}>
        <div className={styles.dammytext}>
          <p>ダミー「結局、人は何がしたいのだろう？」</p>
          <p>人は何がしたいのか、そのことをずっと考えていこう。</p>
          <p>
            まず、あなたが面白いと思うことでなければ、誰かを面白いと思わせることはできないはずだから。
          </p>
          <p>
            もっともらしいブランドスローガンを謳うより、まず自分は何をしたいのか？そう問いかけよう。
          </p>
          <p>
            あなたの周りの人、まだ会ったことのない人は、今いったい何がしたいのだろう？
          </p>
          <p>10年、20年、30年後は、どうだろう？</p>
          <p>そのことをずっと考えていこう。</p>
          <p>
            何をどうすれば、誰もが公平に、したいことができる世の中になるのか？
          </p>
          <p>そのことをずっと考えていこう。</p>
        </div>
        <div className={styles.gazou}>
          <img src="https://placehold.jp/400x300.jpg" />
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
    </div>

    <div className={styles.wrapperflow}>
      <div className={styles.h2}>
        <h2>Flow</h2>
        <p>応募・選考フロー</p>
      </div>
      <p>まずはプレエントリーをお願いします。</p>
      <p>
        プレエントリーいただいた方には会社説明会・選考情報などをご案内させていただきます。
      </p>
      <br />
      <div className={styles.stepcontents}>
        <div className={styles.step1}>
          <p>step1</p>
          <br />
          <p>
            <b>プレエントリー</b>
          </p>
          <p>
            QRコードよりLINEにご登録のうえ
            <br />
            プレエントリーしてください。
          </p>
          <img src="https://placehold.jp/120x120.jpg" />
          <img src="https://placehold.jp/120x120.jpg" />
        </div>

        <div className={styles.step2}>
          <p>step2</p>
          <br />
          <p>
            <b>一次面接</b>
          </p>
          <p>
            ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト
          </p>
        </div>

        <div className={styles.step3}>
          <p>step3</p>
          <br />
          <p>
            <b>二次面接</b>
          </p>
          <p>
            ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト
          </p>
        </div>
      </div>

      <br />
      <br />
      <br />
      <br />
      <br />
    </div>
    <br />
    <br />
    <br />
    <br />

    <div className={styles.wrapperaccess}>
      <div className={styles.h2}>
        <h2>Access</h2>
        <span>面接場所</span>
      </div>
      　　
      <br />
      　<p>まずはプレエントリーをお願いします。</p>
      <p>
        プレエントリーいただいた方には会社説明会・選考情報などをご案内させていただきます。
      </p>
      <br />
      <br />
      <br />
      <table className={styles.info}>
        <tr>
          <th>大阪本社</th>
          <td>
            〒530-0001 大阪府大阪市北区梅田1-12-12 東京建物梅田ビル 11F
            <p>TEL:06-7639-1480</p>
          </td>
        </tr>
        <tr>
          <th>東京本社</th>
          <td>
            〒107-0062 東京都港区南青山1丁目12-3 LIFORK MINAMI AOYAMA S215
            TEL:03-6706-2861
          </td>
        </tr>
        <tr>
          <th>福岡支社</th>
          <td>
            〒810-0001 福岡県福岡市中央区天神1丁目1-1 fabbit Global Gateway
            ACROS Fukuoka
          </td>
        </tr>
        <tr>
          <th>名古屋支社</th>
          <td>〒450-6321 愛知県名古屋市中村区名駅1-1-1 JPタワー名古屋21階</td>
        </tr>
      </table>
      <br />
      <p>詳しくは [働く場所] をご覧ください。</p>
    </div>

    <div className={styles.wrapperinfomation}>
      <div className={styles.h2}>
        <h2>Information</h2>
        <span>ご案内</span>
      </div>

      <div className={styles.infobox}>
        <div className={styles.info1}>
          <p>入社月　選択制度</p>
          <p className={styles.infotext}>
            ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト
          </p>
        </div>

        <div className={styles.info2}>
          <p>総合職のエリア採用（新卒・中途）</p>
          <p className={styles.infotext}>
            ダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキストダミーテキスト
          </p>
        </div>
      </div>
    </div>

    <div className={styles.wrapperfaq}>
      <div className={styles.h2}>
        <h2>FAQ</h2>
        <p>よくある質問</p>
      </div>
      <br />
      <br />
      <br />
      <p>皆さんから多く寄せられたご質問について、回答を掲載しています。</p>
      <br />
      <br />
      <br />
      <nav className={styles.nav}>
        <ul>
          <li>採用・選考について</li>
          <li>仕事について</li>
          <li>福利厚生について</li>
          <li>その他</li>
        </ul>
      </nav>
      <table className={styles.faqtable}>
        <tr>採用・選考について</tr>

        <tr>
          Q. プレエントリー方法について教えてください。
          <p className={styles.faqp}>
            A.プレエントリーは全て当WEBサイトでの受け付けとなります。
            <br />
            プレエントリーいただいた方には、選考情報をご案内させていただきます。
          </p>
        </tr>

        <tr>Q. プレエントリー方法について教えてください。</tr>
        <tr>Q. プレエントリー方法について教えてください。</tr>
        <tr>Q. プレエントリー方法について教えてください。</tr>
        <tr>Q. プレエントリー方法について教えてください。</tr>
      </table>
    </div>
  </DefaultLayout>
);
