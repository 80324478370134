// extracted by mini-css-extract-plugin
export var cover = "m_dS";
export var wrapper = "m_5";
export var h1 = "m_dT";
export var h2 = "m_dV";
export var info = "m_dW";
export var h3 = "m_dX";
export var cf = "m_dY";
export var cover2 = "m_dZ";
export var dammy = "m_d0";
export var dammycontents = "m_d1";
export var dammytext = "m_d2";
export var gazou = "m_bh";
export var wrapperICP = "m_d3";
export var wrapperflow = "m_d4";
export var stepcontents = "m_d5";
export var step1 = "m_d6";
export var step2 = "m_d7";
export var step3 = "m_d8";
export var wrapperaccess = "m_d9";
export var wrapperinfomation = "m_fb";
export var infobox = "m_fc";
export var info1 = "m_fd";
export var info2 = "m_ff";
export var infotext = "m_fg";
export var wrapperfaq = "m_fh";
export var nav = "m_fj";
export var faqtable = "m_fk";
export var faqp = "m_fl";